<template>
    <div>

        <div class="alert alert-danger mt-1 alert-validation-msg" v-for="error in errors">
            <div class="alert-body d-flex align-items-center">
                <span>{{ error }}</span>
            </div>
        </div>

        <div class="row mb-2">
            <div class="form-group">
                <div class="input-group">
                    <input
                        type="text"
                        class="form-control form-control-lg"
                        v-model="formData.term"
                        placeholder="Insert Barcode to Check if Product Inserted Previously"
                    />
                    <span class="input-group-text" id="basic-addon-search1"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg></span>
                </div>
            </div>

        </div>


        <div class="row mb-2">
            <div class="col-md-3 col-12">
                <div class="form-group">
                    <label>Name <span class="text-danger">*</span></label>
                    <input
                        type="text"
                        class="form-control form-control-lg"
                        v-model="formData.name"
                    />
                </div>
            </div>

            <div class="col-md-3 col-12">
                <div class="form-group">
                    <label>Name (English)</label>
                    <input
                        type="text"
                        class="form-control form-control-lg"
                        v-model="formData.en_name"
                    />
                </div>
            </div>

            <div class="col-md-3 col-12">
                <div class="form-group">
                    <label>Category <span class="text-danger">*</span></label>
                    <select
                        class="form-control form-control-lg"
                        v-model="formData.category"
                    >
                        <option value="">Please Select</option>
                        <option
                            :value="category"
                            v-for="category in categories"
                        >
                            {{ category.name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="col-md-3 col-12">
                <div class="form-group">
                    <label>Brand</label>
                    <select class="form-control form-control-lg" v-model="formData.brand">
                        <option value="">Please Select</option>
                        <option :value="brand" v-for="brand in brands">
                            {{ brand.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label class="mb-1">Type <span class="text-danger">*</span></label>
                    <div class="row custom-options-checkable">
                        <div
                            class="col-md-6"
                            v-for="(type, i) in types_list"
                            :key="i"
                        >
                            <div
                                class="border rounded bg-light p-1"
                                :class="{
                                    'select-active': formData.type == type,
                                }"
                                @click="formData.type = type"
                                role="button"
                            >
                                <p class="mb-0">{{ type.value }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-12">
                <div class="row">

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="mb-1">Taxable</label>
                            <div class="form-check form-switch">
                                <input type="checkbox" class="form-check-input" id="customSwitch1" v-model="formData.taxable" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6" v-if="formData.taxable">
                        <div class="form-group">
                            <label class="mb-1">Tax <span class="text-danger">*</span></label>
                            <select class="form-control form-control-lg" v-model="formData.tax">
                                <option value="">Please Select</option>
                                <option :value="tax" v-for="tax in taxes">
                                    {{ tax.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2" v-if="formData.type.key == 'service'">
            <div class="col-md-3 col-12">
                <div class="form-group">
                    <label>Serices Price</label>
                    <input
                        type="text"
                        class="form-control form-control-lg"
                        v-model="formData.service_price"
                    />
                </div>
            </div>
        </div>

        <div v-if="formData.type.key == 'product'">
            <h4>Main Unit <small class="text-danger">Minimum Sealable Unit</small></h4>
            <div class="bg-light p-1 rounded border">
                <div class="row mb-1">
                    <div class="form-group overflo-hidden">
                        <div class="form-check form-switch float-start">
                            <input type="checkbox" class="form-check-input" id="customSwitch1" v-model="formData.is_weight" />
                        </div>
                        <label>Sale by Weight</label>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-md-2 col-12">
                        <div class="form-group">
                            <label>Unit</label>
                            <select class="form-control form-control-lg" v-model="formData.main_unit.name">
                                    <option value="">Please Select</option>
                                    <option :value="u.key" v-for="u in formData.is_weight ? weight_units : each_units">
                                        {{ u.value }}
                                    </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-2 col-12">
                        <div class="form-group">
                            <label>Cost Price</label>
                            <input
                                type="number"
                                class="form-control form-control-lg"
                                placeholder="10"
                                v-model="formData.main_unit.cost_price"
                            />
                            <small class="text-danger">Without Tax</small>
                        </div>
                    </div>

                    <div class="col-md-2 col-12">
                        <div class="form-group">
                            <label>Sale Price</label>
                            <input
                                type="number"
                                class="form-control form-control-lg"
                                placeholder="15"
                                v-model="formData.main_unit.sale_price"
                            />
                            <small class="text-danger">Without Tax</small>
                        </div>
                    </div>

                    <div class="col-md-6 col-12">
                        <div class="form-group">
                            <label>Barcodes</label>
                            <vue3-tags-input
                            :tags="formData.main_unit.barcodes"
                            placeholder="Barcodes Here"
                            class="form-control form-control-tags"
                            @on-tags-changed="handleChangeTag"
                            />
                            <button type="button" class="btn btn-link p-0" @click="generateBarcode">Generate Barcode</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <hr />
        <button class="btn btn-primary waves-effect" type="button" disabled="" v-if="loading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="ms-25 align-middle">Saving...</span>
        </button>
        <button type="button" class="btn btn-primary" @click="validateData" v-else>Save</button>
    </div>
</template>

<script>
import Vue3TagsInput from 'vue3-tags-input';
import axios from 'axios';

export default {
    name: "BranchProducts",
    props: {
        action: { required: true },
        company_id: { required: true },
        categories: { required: true },
        brands: { required: true },
        taxes: { required: true },
        products_count: { required: true },
        product: { required: false },
    },
    components: {
        Vue3TagsInput
    },
    created () {
        if(this.action == 'update') {
            this.formData.name = this.product.name;
            this.formData.en_name = this.product.en_name;
            this.formData.category = this.categories.find(cat => {return cat.id == this.product.category_id});
            this.formData.brand = this.brands.find(brand => {return brand.id == this.product.brand_id});
            this.formData.type = this.types_list.find(type => {return type.key == this.product.type});
            this.formData.taxable = this.product.taxable == 1 ? true : false;
            this.formData.tax = this.taxes.find(t => {return t.id == this.product.tax_id});

            let unit = this.product.units.find(u => {return u.type === 'main'});
            this.formData.main_unit.id = unit.id;
            this.formData.main_unit.name = unit.name;
            this.formData.main_unit.cost_price = parseFloat(unit.cost_price);
            this.formData.main_unit.sale_price = parseFloat(unit.price);
            unit.barcodes.forEach(element => {
                this.formData.main_unit.barcodes.push(element.barcode);
            });
        }
    },
    data() {
        return {
            formData: {
                term: "",
                name: "",
                en_name: "",
                category: "",
                brand: "",
                type: "",
                barcode: "",
                taxable: false,
                tax: "",
                is_weight: false,
                service_price: '',
                main_unit: {
                    id: '',
                    name: '',
                    cost_price: '',
                    sale_price: '',
                    barcodes: []
                },
            },
            errors: [],
            loading: false,
            each_units: [
                {'key':  'pcs', 'value' : 'Piece'},
                {'key':  'dosan', 'value' : 'Dosan'},
                {'key':  'box', 'value' : 'Box'},
            ],
            weight_units: [
                {'key': 'kg', 'value' : 'Kilograms'},
                {'key':  'g', 'value' : 'Grams'},
                {'key': 'm', 'value' : 'Meter'},
                {'key': 'cm', 'value' : 'Centimeter'},
                {'key': 'l', 'value' : 'Litre'},
                {'key': 'ml', 'value' : 'Milliliter'},
            ],
            tag: "",
            tags: [],
            types_list: [
                { key: "product", value: "Product" },
                { key: "service", value: "Service" },
            ],
        };
    },
    methods: {
        generateBarcode(){
            let barcode = '10';
            var seconds = (new Date().getTime() / 1000).toFixed().toString();
            barcode = barcode + this.company_id + seconds;
            if(this.formData.is_weight) {
                barcode = '20';
                let nextProductCode = this.products_count;
                while (nextProductCode.length < 5) {
                    nextProductCode = '0' + nextProductCode;
                }
                barcode = '20' + nextProductCode;
            }
            this.formData.main_unit.barcodes.push(barcode);
        },
        handleChangeTag(tags) {
            this.formData.main_unit.barcodes = tags;
        },
        isNumeric(str) {
            if (typeof str != "string") return false
            return !isNaN(str) && !isNaN(parseFloat(str));
        },
        validateData(){
            this.errors = [];
            if(this.formData.name == '') {
                this.errors.push('Please Fill Name Input'); return;
            }

            if(this.formData.category == '') {
                this.errors.push('Please Select Category'); return;
            }

            if(this.formData.type == '') {
                this.errors.push('Please Select Type'); return;
            }
            if(this.formData.type.key == 'product') {

                if(this.formData.main_unit.name == '') {
                    this.errors.push('Please Select Unit'); return;
                }

                if(this.formData.main_unit.cost_price == '') {
                    console.log('here');
                    this.errors.push('Please Fill Cost Price Input'); return;
                }
                // if(!this.isNumeric(this.formData.main_unit.cost_price)) {
                //     this.errors.push('Please Fill Cost Price Input in Numeric'); return;
                // }

                if(this.formData.main_unit.sale_price == '') {
                    this.errors.push('Please Fill Sale Price Input'); return;
                }

                // if(!this.isNumeric(this.formData.main_unit.sale_price)) {
                //     this.errors.push('Please Fill Sale Price Input in Numeric'); return;
                // }

                if(this.formData.main_unit.barcodes.length < 1) {
                    this.errors.push('Please Fill Barcode Input or Genrate New Barcodes'); return;
                }
            }
            if(this.formData.type.key == 'service') {
                if(this.formData.service_price == '') {
                    this.errors.push('Please Fill Service Price Input'); return;
                }
                if(!this.isNumeric(this.formData.service_price)) {
                    this.errors.push('Please Fill Service Price Input in Numeric'); return;
                }
            }
            if(this.formData.taxable) {
                if(this.formData.tax == '') {
                    this.errors.push('Please Select Tax'); return;
                }
            }
            this.storeData();
        },

        storeData(){
            this.loading = true;
            let url = "/products/";

            let formData = new FormData();
            formData.append("name", this.formData.name);
            formData.append("en_name", this.formData.en_name);
            formData.append("category_id", this.formData.category.id);
            if(this.formData.brand != '') {
                formData.append("brand_id", this.formData.brand.id);
            }
            formData.append("type", this.formData.type.key);
            formData.append("taxable", this.formData.taxable ? 1 : 0);
            formData.append("tax_id", '');
            if(this.formData.taxable) {
                formData.append("tax_id", this.formData.tax.id);
            }
            formData.append("is_weight", this.formData.is_weight ? 1 : 0);
            formData.append("uom", this.formData.is_weight ? 'weight' : 'each');
            formData.append("price", this.formData.service_price);
            formData.append("main_unit", JSON.stringify(this.formData.main_unit));
            if(this.action == 'update') {
                formData.append("_method", 'put');
                url = "/products/" + this.product.id;
            }

            axios
                .post(url, formData, { headers: this.headers })
                .then((response) => {
                    setTimeout(() => {
                        window.location.href = response.data;
                    }, 1000);
                })
                .catch((e) => {
                    this.loading = false;
                    for (const [key, value] of Object.entries(
                        e.response.data.errors
                    )) {
                        this.errors.push(`${value}`);
                    }
                });
        },

    }
};
</script>

<style scoped>
.select-active {
    background-color: rgba(115, 103, 240, 0.12) !important;
    color: #7367f0 !important;
    border-color: #7367f0 !important;
}
.v3ti {
    border:  1px solid #d8d6de !important;
}
.v3ti--focus{
    border:  1px solid #7367f0 !important;
}
.v3ti .v3ti-new-tag {
    height: auto !important;
}
</style>
