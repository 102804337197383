<template>
    <div>
        ProductsTable
        <br>
        <DataTable :value="products" removableSort tableStyle="min-width: 50rem">
            <Column field="code" header="Code" sortable style="width: 25%"></Column>
            <Column field="name" header="Name" sortable style="width: 25%"></Column>
            <Column field="category" header="Category" sortable style="width: 25%"></Column>
            <Column field="quantity" header="Quantity" sortable style="width: 25%"></Column>
        </DataTable>
        <hr>
    </div>
</template>

<script>
export default {
    name: "ProductsTable",
    props: {
        products: {required: true},
    },
    components: {
    },
    created () {


    },
    data() {
        return {
            formData: {

            },
            errors: [],
            loading: false,
        };
    },
    methods: {

    }
};
</script>

<style scoped>

</style>
