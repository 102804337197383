<template>
    <div>

        <div class="alert alert-danger mt-1 alert-validation-msg" v-for="error in errors">
            <div class="alert-body d-flex align-items-center">
                <span>{{ error }}</span>
            </div>
        </div>
                <div class="row my-3">
                        <div class="col-12 col-md-3 form-group">
                            <label>{{ lang.Name }} <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-lg" v-model="formData.name" required autofocus/>
                        </div>

                        <div class="col-12 col-md-3 form-group">
                            <label>{{ lang.Branch }} <span class="text-danger">*</span></label>
                            <select class="form-control form-control-lg" v-model="formData.branch" :disabled="branches.length == 1">
                                <option value="">{{ lang.please_select }}</option>
                                <option :value="branch" v-for="branch in branches" :key="branch.id">{{ branch.name }}</option>
                            </select>
                        </div>
                        </div>
                        <div class="row my-3">

                        <div class="col-12 col-md-3 form-group">
                            <label>{{ lang.DiscountType }} <span class="text-danger">*</span></label>
                            <div class="demo-inline-spacing">
                        <div class="form-check form-check-inline col">
                            <input class="form-check-input" type="radio" v-model="formData.discount_type" id="inlineRadio1" value="percent" checked="">
                            <label class="form-check-label" for="inlineRadio1">{{ lang.Percent }}</label>
                        </div>
                        <div class="form-check form-check-inline col">
                            <input class="form-check-input" type="radio" v-model="formData.discount_type" id="inlineRadio2" value="fixed">
                            <label class="form-check-label" for="inlineRadio2">{{ lang.Fixed }}</label>
                        </div>
                    </div>
                        </div>

                        <div class="col-12 col-md-3 form-group">
                            <label>{{ lang.DiscountAmount }} <span class="text-danger">*</span></label>
                            <input type="number" step="0.1" class="form-control form-control-lg" v-model="formData.discount_amount"/>
                        </div>

                        <div class="col-12 col-md-3 form-group">
                            <label>{{ lang.DiscountIncludeTax }} <span class="text-danger">*</span></label>
                            <div class="demo-inline-spacing">
                            <input class="form-check-input" type="checkbox" v-model="formData.include_tax">
                    </div>
                        </div>
                    </div>


                    <div class="row mb-2">
                        <div class="col">
                            <div class="form-group">
                                <label>{{ lang.StartDate }} <span class="text-danger">*</span></label>
                                <input type="date" class="form-control form-control-lg" v-model="formData.start_date"/>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>{{ lang.EndDate }} <span class="text-danger">*</span></label>
                                <input type="date" class="form-control form-control-lg" v-model="formData.end_date"/>
                            </div>
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label>{{ lang.StartTime }} <span class="text-danger">*</span></label>
                                <input type="time" class="form-control form-control-lg" v-model="formData.start_time"/>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>{{ lang.EndTime }} <span class="text-danger">*</span></label>
                                <input type="time" name="mobile" class="form-control form-control-lg" v-model="formData.end_time"/>
                            </div>
                        </div>
                    </div>

                    <h4>{{ lang.Products }}</h4>

                    <table class="table table-bordered table-hover mb-2">
                        <thead>
                            <tr>
                                <th>{{ lang.Name }}</th>
                                <th>{{ lang.Unit }}</th>
                                <th>{{ lang.Price }}</th>
                                <th>
                                    <a href="#" @click="addAll">{{ lang.CheckAll }}</a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(product, i) in products_list" :key="i">
                                <td>{{ product.name }}</td>
                                <td>{{ product.unit_name }}</td>
                                <td>{{ product.price }}</td>
                                <td>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" v-model="formData.products" :value="product.branch_product_id" checked="">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

        <div>
            <button class="btn btn-primary waves-effect" type="button" disabled="" v-if="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="ms-25 align-middle">{{ lang.Saving }}</span>
            </button>
            <button type="button" class="btn btn-primary" @click="validateData" v-else>{{ lang.Save }}</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "PromotionForm",
    props: {
        action: { required: true },
        lang: { required: true },
        branches: { required: true },
    },
    components: {vSelect},
    created () {
        this.getProducts();
    },
    data() {
        return {
            formData: {
                type: 'simple',
                name: '',
                branch: this.branches[0],
                discount_type: 'percent',
                discount_amount: '',
                start_date: '',
                end_date: '',
                start_time: '',
                end_time: '',
                products: [],
                include_tax: false,
            },
            products_list: [],
            errors: [],
            loading: false,
        };
    },
    watch: {

    },
    methods: {

        getProducts(){
            this.loading = true;
            let url = "/box/prepare-data";
            let formData = new FormData();
            axios
                .post(url, formData, { headers: this.headers })
                .then((response) => {
                    this.loading = false;
                    this.products_list = response.data;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.response.data);
                });
        },
        addAll(){
            this.products_list.forEach((p) => this.formData.products.push(p.branch_product_id));
        },
        deleteFromList(index){
            this.formData.products.splice(index, 1);
        },
        validateData(){
            this.errors = [];

            if(this.formData.name == '') {
                this.errors.push('Please Fill Name Input'); return;
            }

            if(this.formData.discount_amount == '') {
                this.errors.push('Please Fill Discount Amount Input'); return;
            }

            if(this.formData.start_date == '') {
                this.errors.push('Please Fill Start Date Input'); return;
            }

            if(this.formData.end_date == '') {
                this.errors.push('Please Fill End Date Input'); return;
            }

            if(this.formData.start_time == '') {
                this.errors.push('Please Fill Start Time Input'); return;
            }

            if(this.formData.end_time == '') {
                this.errors.push('Please Fill End Time Input'); return;
            }

            if(this.formData.products.length < 1) {
                this.errors.push('Please Add at latest One Product'); return;
            }


            this.storeData();
        },

        storeData(){
            this.loading = true;
            let url = "/promotions";
            let formData = new FormData();
            formData.append("type", this.formData.type);
            formData.append("name", this.formData.name);
            formData.append("branch_id", this.formData.branch.id);
            formData.append("discount_type", this.formData.discount_type);
            formData.append("discount_amount", this.formData.discount_amount);
            formData.append("start_date", this.formData.start_date);
            formData.append("end_date", this.formData.end_date);
            formData.append("start_time", this.formData.start_time);
            formData.append("end_time", this.formData.end_time);
            formData.append("include_tax", this.formData.include_tax ? 1 : 0);
            formData.append("products", JSON.stringify(this.formData.products));

            axios
                .post(url, formData, { headers: this.headers })
                .then((response) => {
                    setTimeout(() => {
                        window.location.href = response.data;
                    }, 1000);
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.response.data);
                    for (const [key, value] of Object.entries(
                        e.response.data.errors
                    )) {
                        this.errors.push(`${value}`);
                    }
                });
        },

    }
};
</script>

<style scoped>
.select-active {
    background-color: rgba(115, 103, 240, 0.12) !important;
    color: #7367f0 !important;
    border-color: #7367f0 !important;
}
.v3ti {
    border:  1px solid #d8d6de !important;
}
.v3ti--focus{
    border:  1px solid #7367f0 !important;
}
.v3ti .v3ti-new-tag {
    height: auto !important;
}
</style>
