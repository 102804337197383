<template>
    <div>

        <div class="alert alert-danger mt-1 alert-validation-msg" v-for="error in errors">
            <div class="alert-body d-flex align-items-center">
                <span>{{ error }}</span>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label class="mb-1">{{ lang.Type }} <span class="text-danger">*</span></label>
                    <div class="row custom-options-checkable">
                        <div class="col-md-6" v-for="(type, i) in types_list" :key="i" >
                            <div
                            class="border rounded bg-light p-1"
                            :class="{ 'select-active': formData.type == type, }"
                            @click="formData.type = type"
                            role="button"
                            >
                            <p class="mb-0">{{ type.value }}</p>
                            <small>{{ type.desc }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-2">
                <div class="col-md-4 col-12">
                    <div class="form-group">
                        <label>{{ lang.Supplier }}</label>
                        <select class="form-control form-control-lg" v-model="formData.supplier">
                            <option value="">{{ lang.PleaseSelect }}</option>
                            <option :value="supplier" v-for="supplier in suppliers" :key="supplier.id">{{ supplier.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2 col-12">
                    <div class="form-group">
                        <button @click="new_supplier = !new_supplier" class="btn btn-light mt-2">
                            {{ lang.AddnewSupplier }}
                        </button>
                    </div>
                </div>
                <div class="col-md-6 col-12" v-if="new_supplier">
                    <div class="form-group">
                        <label>{{ lang.SupplierName }} <span class="text-danger">*</span></label>
                        <input type="text" class="form-control form-control-lg" v-model="formData.supplier_name">
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-md-4 col-12">
                    <div class="form-group">
                        <label>{{ lang.StockedAtBranch }}</label>
                        <select class="form-control form-control-lg" v-model="formData.branch" :disabled="branches.length == 1">
                            <option value="">{{ lang.PleaseSelect }}</option>
                            <option :value="branch" v-for="branch in branches" :key="branch.id">{{ branch.name }}</option>
                        </select>
                    </div>
                </div>
            </div>

        <div v-if="formData.type && formData.type.key == 'purchases'">

            <hr class="my-3">
            <h4 class="mb-2">{{ lang.Products }}</h4>
            <div class="row mb-2">
                <div class="col-md-8 col-12">
                    <v-select
                        class="style-chooser mb-3"
                        label="name"
                        :options="products_list"
                        placeholder="Search Product"
                        v-model="selected_products"
                        >
                        </v-select>
                        <div class="table-bordered">

                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>{{ lang.Product }}</th>
                                        <th>{{ lang.Quantity }}</th>
                                        <th>{{ lang.NewCostPrice }}</th>
                                        <th>{{ lang.NewSalePrice }}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(product, i) in formData.products" :key="i">
                                        <td>{{ product.name }}</td>
                                        <td><input type="text" class="form-control" v-model="product.quantity"></td>
                                        <td><input type="text" class="form-control" v-model="product.cost_price"></td>
                                        <td><input type="text" class="form-control" v-model="product.price"></td>
                                        <td><button type="button" class="btn btn-danger btn-sm" @click="deleteFromList(i)">x</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                </div>
                <div class="col-md-4 col-12 border-start">
                    <div class="bg-light p-2 rounded">

                        <div class="form-group mb-1">
                            <label>{{ lang.Subtotal }}</label>
                            <input type="text" class="form-control form-control-lg" :value="computedSubtotal" disabled>
                        </div>
                        <div class="form-group mb-1">
                            <label>{{ lang.Tax }}</label>
                            <input type="text" class="form-control form-control-lg" v-model="formData.tax">
                        </div>
                        <div class="form-group mb-1">
                            <label>{{ lang.Total }} <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-lg" :value="computedTotal" disabled>
                        </div>
                        <div class="form-group mb-1">
                            <label>{{ lang.Paid }} <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-lg" v-model="formData.paid">
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div v-if="formData.type && formData.type.key == 'expenses'">
            <div class="row">
                <div class="col-md-3 col-12">
                    <div class="form-group mb-1">
                            <label>{{ lang.Subtotal }}</label>
                            <input type="text" class="form-control form-control-lg" v-model="formData.subtotal" >
                        </div>
                </div>
                <div class="col-md-3 col-12">
                    <div class="form-group mb-1">
                            <label>{{ lang.Tax }}</label>
                            <input type="text" class="form-control form-control-lg" v-model="formData.tax">
                        </div>
                </div>
                <div class="col-md-3 col-12">
                    <div class="form-group mb-1">
                            <label>{{ lang.Total }} <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-lg" v-model="formData.total" >
                        </div>
                </div>
                <div class="col-md-3 col-12">
                    <div class="form-group mb-1">
                            <label>{{ lang.Paid }} <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-lg" v-model="formData.paid">
                        </div>
                </div>
            </div>
        </div>
        <hr>
        <h4 class="mb-2">{{ lang.PaymentMethod }}</h4>
        <div class="row mb-1 col-8">
            <div class="col" v-for="(pMethod, i) in payment_methods" :key="pMethod.id">
                <div
                role="button"
                class="payment-method bg-light rounded p-1 text-center"
                :style="{ 'background' : formData.payment_method.id == pMethod.id ? '#d1faddbb !important' : '', 'border' : formData.payment_method.id == pMethod.id ? '2px solid #b5efc6 !important' : '2px solid #eee !important' }"
                @click="formData.payment_method = pMethod">
                <h4 class="mb-0">
                    {{ pMethod.name }}
                </h4>
            </div>
        </div>
        </div>
        <hr>
        <div class="row mb-2">
            <div class="col-md-12 col-12">
                <div class="form-group">
                    <label>{{ lang.Note }}</label>
                    <textarea class="form-control form-control-lg" v-model="formData.note"></textarea>
                </div>
            </div>

        </div>

        <hr />
        <button class="btn btn-primary waves-effect" type="button" disabled="" v-if="loading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="ms-25 align-middle">{{ lang.Saving }}</span>
        </button>
        <button type="button" class="btn btn-primary" @click="validateData" v-else>{{ lang.Save }}</button>
    </div>
</template>

<script>
import axios from 'axios';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "PurchasesForm",
    props: {
        action: { required: true },
        lang: { required: true },
        suppliers: { required: true },
        payment_methods: { required: true },
        branches: { required: true },
    },
    components: {vSelect},
    created () {
        this.getProducts();
        if(this.action == 'update') {
        }
    },
    data() {
        return {
            formData: {
                type: '',
                supplier: '',
                branch: this.branches[0],
                subtotal: '',
                tax: '',
                total: '',
                paid: '',
                payment_type: 'cash',
                payment_method: this.payment_methods[0],
                note: '',
                supplier_name: '',
                products: [],
            },
            selected_products: [],
            products_list: [],
            new_supplier: false,
            types_list: [
                { key: "purchases", value: "Purchases Pill", 'desc': 'this is description 1' },
                { key: "expenses", value: "Expenses", 'desc': 'this is description 2' },
            ],
            errors: [],
            loading: false,
        };
    },
    watch: {
        selected_products(newVal) {
            let p = {id: newVal.id, name: newVal.name, unit_id: newVal.unit_id, quantity: 0, price: newVal.price, cost_price: newVal.cost_price};
            this.formData.products.push(Object.assign({}, p));
        }
    },
    computed: {
        computedSubtotal(){
            let subtotal = this.formData.subtotal;
            if(this.formData.type && this.formData.type.key == 'purchases') {
                subtotal = 0;
                this.formData.products.forEach((p) => {
                    subtotal += p.quantity * p.cost_price;
                });
            }
            return subtotal;
        },
        computedTotal(){
            let total =  this.formData.total;
            if(this.formData.type && this.formData.type.key == 'purchases') {
                total =  Number(this.computedSubtotal) + Number(this.formData.tax);
            }
            return total;
        },
    },
    methods: {
        deleteFromList(index){
            this.formData.products.splice(index, 1);
        },
        getProducts(){
            this.loading = true;
            let url = "/box/prepare-data";
            let formData = new FormData();
            axios
                .post(url, formData, { headers: this.headers })
                .then((response) => {
                    this.loading = false;
                    this.products_list = response.data;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.response.data);
                });
        },
        isNumeric(str) {
            if (typeof str != "string") return false
            return !isNaN(str) && !isNaN(parseFloat(str));
        },
        validateData(){
            this.errors = [];
            if(this.formData.type == '') {
                this.errors.push('Please Select Type'); return;
            }

            if(this.new_supplier && this.formData.supplier_name == '') {
                this.errors.push('Please Fill Supplier Name Input'); return;
            }

            if(!this.new_supplier && this.formData.supplier == '') {
                this.errors.push('Please Select Supplier'); return;
            }

            if(this.formData.type && this.formData.type.key == 'purchases'){

                if(this.formData.products.length < 1){
                    this.errors.push('Please Add at least One Product'); return;
                }

            }

            if(this.formData.type && this.formData.type.key == 'expenses'){

                if(this.formData.subtotal == ''){
                    this.errors.push('Please Fill Subtotal Input'); return;
                }
                if(this.formData.total == ''){
                    this.errors.push('Please Fill Total Input'); return;
                }


            }



            if(this.formData.paid == '') {
                this.errors.push('Please Fill Paid Input'); return;
            }


            this.storeData();
        },

        storeData(){
            this.loading = true;
            let url = "/purchases";
            let formData = new FormData();
            formData.append("type", this.formData.type.key);
            formData.append("supplier_id", this.formData.supplier ? this.formData.supplier.id : '');
            formData.append("branch_id", this.formData.branch.id);
            formData.append("new_supplier", this.new_supplier ? 1 : 0);
            formData.append("supplier_name", this.formData.supplier_name);
            formData.append("products", JSON.stringify(this.formData.products));
            formData.append("subtotal", this.computedSubtotal);
            formData.append("tax", this.formData.tax);
            formData.append("total", this.computedTotal);
            formData.append("paid", this.formData.paid);
            formData.append("note", this.formData.note);
            formData.append("payment_method_id", this.formData.payment_method.id);

            axios
                .post(url, formData, { headers: this.headers })
                .then((response) => {
                    console.log(response.data);
                    setTimeout(() => {
                        window.location.href = response.data;
                    }, 1000);
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.response.data);
                    for (const [key, value] of Object.entries(
                        e.response.data.errors
                    )) {
                        this.errors.push(`${value}`);
                    }
                });
        },

    }
};
</script>

<style scoped>
.select-active {
    background-color: rgba(115, 103, 240, 0.12) !important;
    color: #7367f0 !important;
    border-color: #7367f0 !important;
}
.v3ti {
    border:  1px solid #d8d6de !important;
}
.v3ti--focus{
    border:  1px solid #7367f0 !important;
}
.v3ti .v3ti-new-tag {
    height: auto !important;
}
</style>
