
import axios from 'axios';

export default {

    // Check Internet
    //  Increment Quantities By Up Keyword
    mounted() {
        //  Get Products
        this.getProducts();
        this.successAudio = new Audio('/alerts/alert1.mp3');
        this.failedAudio = new Audio('/alerts/alert2.mp3');

        let self = this;
        window.addEventListener('keyup', function(ev) {
            // console.log(ev.key);
            if(ev.key == 'Enter') {
                self.searchProduct();
            }
            if(ev.key == 'ArrowUp'){
                self.increaseProduct(self.selectedRow);
            }
            if(ev.key == 'ArrowDown'){
                self.decreaseProduct(self.selectedRow);
            }
            if(ev.key == '='){
                self.openPaymentModal();
            }
        });
    },

    watch : {
        customer_term(oldVal, newVal){
            this.customers_list = [];
            this.customers.forEach((customer) => {
                if(customer.name.includes(newVal) || customer.mobile.includes(newVal)) {
                    this.customers_list.push(customer);
                }
            });
        },
        'formData.payment.discount'(newVal){
            console.log(newVal);
            if(newVal > this.cartTotal) {
                this.formData.payment.discount = 0;
            }
            this.formData.payment.paid = this.cartTotal - newVal;
        }
    },
    computed:{

        cartSubtotal(){
            let stotal = 0;
            this.formData.cart.forEach((p) => {
                stotal += p.price * p.count;
            });

            return stotal.toFixed(2);
        },
        cartTax(){
            let tax = 0;
            this.formData.cart.forEach((p) => {
                if(p.tax_value) {
                    tax += ((p.price * (1 + '.' + p.tax_value)) - p.price) * p.count;
                }
            });
            return tax.toFixed(2);
        },
        promotionDiscount(){
            let discount = 0;
            if(this.promotion){
                this.formData.cart.forEach((p) => {
                    if(this.promotion.products.includes(p.branch_product_id)) {
                        let percent = this.promotion.discount_amount / 100;
                        console.log((p.price * p.count) );
                        console.log(((p.price * p.count) * percent));
                        discount +=  ((p.price * p.count) * percent);
                    }
                });
            }
            return discount.toFixed(2);
        },
        cartTotal(){
            let stotal = 0;
            let tax = 0;
            this.formData.cart.forEach((p) => {
                stotal += p.price * p.count;
                if(p.tax_value) {
                    tax += ((p.price * (1 + '.' + p.tax_value)) - p.price) * p.count;
                }
            });

            return ((stotal + tax) - this.promotionDiscount).toFixed(2);
        },

        // cartSummeryData(){
        //     let stotal = 0;
        //     let tax = 0;
        //     this.formData.cart.forEach((p) => {
        //         stotal += p.price * p.count;
        //         if(p.tax_value) {
        //             tax += ((p.price * (1 + '.' + p.tax_value)) - p.price) * p.count;
        //         }
        //     });

        //     return {
        //         products: this.formData.cart.length,
        //         subtotal: stotal.toFixed(2),
        //         tax: tax.toFixed(2),
        //         total: (stotal + tax).toFixed(2),
        //     }
        // },

        cartRemaining(){
            let remaining = 0;
            let text = 'for customer';
            if(this.formData.cart.length) {
                remaining = (this.formData.payment.paid + this.formData.payment.discount) - this.cartTotal;
                if(remaining < 0) {
                    text = 'for you';
                }
            }
            return {remaining: Math.abs(remaining), text: text};
        }

    },



    methods: {
        categorizeProducts(index){
            if(this.categories[index]) {
                this.selectedProducts = this.products.filter((p) => p.category_id == this.categories[index].id);
            }
        },
        enableNewCustomerForm(){
            this.formData.customer = {
                id: '',
                name: '',
                email: '',
                mobile: '',
                tax_number: '',
            };
            this.add_new_customer = !this.add_new_customer;
        },
        openPaymentModal(){
            this.term = '';
            if(this.formData.cart.length) {
                let paymentModal = new bootstrap.Modal(document.getElementById("paymentModal"), {});
                this.formData.payment.paid = this.cartTotal;
                this.formData.payment.total = this.cartTotal;
                paymentModal.show();
            }
        },
        closePaymentModal(){
            this.term = '';
            $('#paymentModal').modal('hide');
        },
        searchProduct(){
            // Validate Term Input
            let result = this.products.find((element) => element.barcode == this.term);
            // search for Barcode
            if(result) {
                this.addToCart(result);
                this.term = '';
            }else {
                this.failedAudio.play();
                this.term = '';
            }
        },

        addToCart (product){
            this.successAudio.play();
            let check = this.formData.cart.find((element) => element.barcode == product.barcode);
            if(check) {
                check.count++;
                return;
            }
            product.count = 1;
            this.formData.cart.push(Object.assign({}, product));
            this.selectedRow = this.formData.cart.length - 1;
        },

        deleteFromCart(index){
            this.formData.cart.splice(index, 1);
            this.failedAudio.play();
        },

        increaseProduct(index){
            if(this.formData.cart.length) {
                this.formData.cart[index].count++;
                this.successAudio.play();
            }
        },
        decreaseProduct(index){
            if(this.formData.cart.length) {
                if(this.formData.cart[index].count > 1) {
                    this.formData.cart[index].count--;
                    this.failedAudio.play();
                }
            }
        },
        getProducts(){
            this.loading = true;
            let url = "/box/prepare-data";
            let formData = new FormData();
            axios
                .post(url, formData, { headers: this.headers })
                .then((response) => {
                    this.products = response.data;
                    this.selectedProducts = response.data;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.response.data);
                });
        },
        storeCart(){
            this.loading = true;
            let url = "/transactions";
            let formData = new FormData();
            formData.append("products", JSON.stringify(this.formData.cart));
            formData.append("payment_method_id", this.formData.payment_method.id);
            formData.append("has_customer", this.formData.customer.name != '' ? 1 : 0);
            formData.append("is_new_customer", this.add_new_customer ? 1 : 0);
            formData.append("customer", JSON.stringify(this.formData.customer));
            formData.append("payment", JSON.stringify(this.formData.payment));
            formData.append("subtotal", this.cartSubtotal);
            formData.append("tax", this.cartTax);
            formData.append("total", this.cartTotal);
            formData.append("discount", this.formData.payment.discount);
            formData.append("remaining", this.cartRemaining.remaining);
            if(this.promotion != null) {
                formData.append("has_promotion", 1);
                formData.append("promotion_id", this.promotion.id);
                formData.append("promotion_discount", this.promotionDiscount);
            }

            axios
                .post(url, formData, { headers: this.headers })
                .then((response) => {
                    this.printTransaction(response.data).then((res) => {
                        this.resetAll();
                        this.closePaymentModal();
                    }).catch(err => {
                        console.log(err);
                    });

                })
                .catch((e) => {
                    this.loading = false;
                });

        },
        resetAll(){
            this.formData = {
                cart: [],
                payment_method: this.payment_methods[0],
                customer: { id: '', name: '', email: '', mobile: '', tax_number: ''},
                payment: { paid: '', discount: 0, total: ''}
            };
            this.add_new_customer = false;
            this.selectedRow = '';
        },
        // internetStatus(ele) {
        //     this.onLine = ele;
        // },
        // event(ele) {
        //     this.onLine === false ?  this.errors.push("Please Check your Internet Connection") : this.errors = [];
        // },

        // prepareData(){
        //     let formData = new FormData();
        //     let headers = {'content-type': 'application/json'};
        //     let url = '/panel/boxes/' + this.box.id + '/get-products';
        //     axios.post(url, formData, { headers: headers })
        //         .then((response) => {
        //             this.$store.dispatch('SetProducts', response.data.products);
        //             this.$store.dispatch('SetCategories', response.data.categories);
        //             this.$store.dispatch('SetCustomers', response.data.customers);
        //             this.loading = false;
        //         })
        //         .catch(error => {
        //             console.log(error.response)
        //         });
        // },


        async printTransaction(transaction) {
            let url = "/transactions/" + transaction.id + "/print";
            var mywindow = window.open(url, 'PRINT', 'height=700,width=500');
            mywindow.addEventListener('load', mywindow.print(), false);
            mywindow.focus();
            mywindow.print();
            // mywindow.close();
            return true;
        },














    }, // Methods

};



