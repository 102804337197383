<template>
    <div>

        <div class="alert alert-danger mt-1 alert-validation-msg" v-for="error in errors">
            <div class="alert-body d-flex align-items-center">
                <span>{{ error }}</span>
            </div>
        </div>


        <div class="mt-3">
            <div class="">
                <div class="row mb-1">
                    <div class="form-group overflo-hidden">
                        <div class="form-check form-switch float-start">
                            <input type="checkbox" class="form-check-input" id="customSwitch1" v-model="formData.is_weight" />
                        </div>
                        <label>{{ lang.SalebyWeight }}</label>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-md-12 col-12 mb-1">
                        <div class="form-group">
                            <label>{{ lang.Unit }}</label>
                            <select class="form-control form-control-lg" v-model="formData.name">
                                    <option value="">{{ lang.please_select }}</option>
                                    <option :value="u.key" v-for="u in formData.is_weight ? weight_units : each_units">
                                        {{ u.value }}
                                    </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6 col-12 mb-1">
                        <div class="form-group">
                            <label>{{ lang.CostPrice }}</label>
                            <input
                                type="number"
                                class="form-control form-control-lg"
                                placeholder="10"
                                v-model="formData.cost_price"
                            />
                            <small class="text-danger">{{ lang.WithoutTax }}</small>
                        </div>
                    </div>

                    <div class="col-md-6 col-12 mb-1">
                        <div class="form-group">
                            <label>{{ lang.SalePrice }}</label>
                            <input
                                type="number"
                                class="form-control form-control-lg"
                                placeholder="15"
                                v-model="formData.sale_price"
                            />
                            <small class="text-danger">{{ lang.WithoutTax }}</small>
                        </div>
                    </div>

                    <div class="col-md-12 col-12 mb-1">
                        <div class="form-group">
                            <div class="input-group mb-2">
                                <span class="input-group-text">{{ lang.ThisunitHas }}</span>
                                <input type="text" class="form-control form-control-lg" placeholder="10" v-model="formData.average">
                                <span class="input-group-text">{{ main_unit.name }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-12">
                        <div class="form-group">
                            <label>{{ lang.Barcodes }}</label>
                            <vue3-tags-input
                            :tags="formData.barcodes"
                            placeholder="Barcodes Here"
                            class="form-control form-control-tags"
                            @on-tags-changed="handleChangeTag"
                            />
                            <button type="button" class="btn btn-link p-0" @click="generateBarcode">{{ lang.GenerateBarcode }}</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <hr />
        <button class="btn btn-primary waves-effect" type="button" disabled="" v-if="loading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="ms-25 align-middle">{{ lang.Saving }}</span>
        </button>
        <button type="button" class="btn btn-primary" @click="validateData" v-else>{{ lang.Save }}</button>
    </div>
</template>

<script>
import Vue3TagsInput from 'vue3-tags-input';
import axios from 'axios';

export default {
    name: "ProductUnitForm",
    props: {
        action: { required: true },
        lang: { required: true },
        company_id: { required: true },
        products_count: { required: true },
        product: { required: true },
        main_unit: { required: true },
        unit: { required: false },
    },
    components: {
        Vue3TagsInput
    },
    created () {
        if(this.action == 'update') {
            this.formData.id = this.unit.id;
            this.formData.name = this.unit.name;
            this.formData.cost_price = parseFloat(this.unit.cost_price);
            this.formData.sale_price = parseFloat(this.unit.price);
            this.formData.average = parseFloat(this.unit.average);
            this.formData.is_weight = this.unit.is_weight == 1 ? true : false;

            this.unit.barcodes.forEach(element => {
                this.formData.barcodes.push(element.barcode);
            });


        }
    },
    data() {
        return {
            formData: {
                id: '',
                name: "",
                cost_price: '',
                sale_price: '',
                average: '',
                is_weight: false,
                barcodes: [],
            },
            errors: [],
            loading: false,
            each_units: [
                {'key':  'pcs', 'value' : 'Piece'},
                {'key':  'dosan', 'value' : 'Dosan'},
                {'key':  'box', 'value' : 'Box'},
            ],
            weight_units: [
                {'key': 'kg', 'value' : 'Kilograms'},
                {'key':  'g', 'value' : 'Grams'},
                {'key': 'm', 'value' : 'Meter'},
                {'key': 'cm', 'value' : 'Centimeter'},
                {'key': 'l', 'value' : 'Litre'},
                {'key': 'ml', 'value' : 'Milliliter'},
            ],
            tag: "",
            tags: [],
            types_list: [
                { key: "product", value: "Product" },
                { key: "service", value: "Service" },
            ],
        };
    },
    methods: {
        generateBarcode(){
            let barcode = '10';
            var seconds = (new Date().getTime() / 1000).toFixed().toString();
            barcode = barcode + this.company_id + seconds;
            if(this.formData.is_weight) {
                barcode = '20';
                let nextProductCode = this.products_count;
                while (nextProductCode.length < 5) {
                    nextProductCode = '0' + nextProductCode;
                }
                barcode = '20' + nextProductCode;
            }
            this.formData.barcodes.push(barcode);
        },
        handleChangeTag(tags) {
            this.formData.barcodes = tags;
        },
        isNumeric(str) {
            if (typeof str != "string") return false
            return !isNaN(str) && !isNaN(parseFloat(str));
        },
        validateData(){
            this.errors = [];

                if(this.formData.name == '') {
                    this.errors.push('Please Select Unit'); return;
                }

                if(this.formData.cost_price == '') {
                    console.log('here');
                    this.errors.push('Please Fill Cost Price Input'); return;
                }

                if(this.formData.sale_price == '') {
                    this.errors.push('Please Fill Sale Price Input'); return;
                }

                if(this.formData.barcodes.length < 1) {
                    this.errors.push('Please Fill Barcode Input or Genrate New Barcodes'); return;
                }

            this.storeData();
        },

        storeData(){
            this.loading = true;
            let url = "/products/" + this.product.id +"/units";

            let formData = new FormData();
            formData.append("id", this.formData.id);
            formData.append("name", this.formData.name);
            formData.append("cost_price", this.formData.cost_price);
            formData.append("sale_price", this.formData.sale_price);
            formData.append("average", this.formData.average);
            formData.append("is_weight", this.formData.is_weight ? 1 : 0);
            formData.append("barcodes", JSON.stringify(this.formData.barcodes));
            formData.append("uom", this.formData.is_weight ? 'weight' : 'each');

            if(this.action == 'update') {
                formData.append("_method", 'put');
                url = "/products/" + this.product.id + '/units/' + this.unit.id;
            }

            axios
                .post(url, formData, { headers: this.headers })
                .then((response) => {
                    setTimeout(() => {
                        window.location.href = response.data;
                    }, 1000);
                })
                .catch((e) => {
                    this.loading = false;
                    for (const [key, value] of Object.entries(
                        e.response.data.errors
                    )) {
                        this.errors.push(`${value}`);
                    }
                });
        },

    }
};
</script>

<style scoped>
.select-active {
    background-color: rgba(115, 103, 240, 0.12) !important;
    color: #7367f0 !important;
    border-color: #7367f0 !important;
}
.v3ti {
    border:  1px solid #d8d6de !important;
}
.v3ti--focus{
    border:  1px solid #7367f0 !important;
}
.v3ti .v3ti-new-tag {
    height: auto !important;
}
</style>
