<template>
    <div>

        <div class="alert alert-danger mt-1 alert-validation-msg" v-for="error in errors">
            <div class="alert-body d-flex align-items-center">
                <span>{{ error }}</span>
            </div>
        </div>

        <!-- <div class="row mb-2">
            <div class="form-group">
                <div class="input-group">
                    <input
                        type="text"
                        class="form-control form-control-lg"
                        v-model="formData.term"
                        :placeholder="lang.check_barcodes_prev"
                    />
                    <span class="input-group-text" id="basic-addon-search1"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg></span>
                </div>
            </div>

        </div> -->


        <div class="row mb-2">
            <div class="col-md-3 col-12">
                <div class="form-group">
                    <label>{{ lang.Name }} <span class="text-danger">*</span></label>
                    <input
                        type="text"
                        class="form-control form-control-lg"
                        v-model="formData.name"
                    />
                </div>
            </div>

            <div class="col-md-3 col-12">
                <div class="form-group">
                    <label>{{ lang.Name }} (English)</label>
                    <input
                        type="text"
                        class="form-control form-control-lg"
                        v-model="formData.en_name"
                    />
                </div>
            </div>

            <div class="col-md-3 col-12">
                <div class="form-group">
                    <label>{{ lang.Category }} <span class="text-danger">*</span></label>
                    <select
                        class="form-control form-control-lg"
                        v-model="formData.category"
                    >
                        <option value="">{{ lang.please_select }}</option>
                        <option
                            :value="category"
                            v-for="category in categories"
                        >
                            {{ category.name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="col-md-3 col-12">
                <div class="form-group">
                    <label>{{ lang.Brand }}</label>
                    <select class="form-control form-control-lg" v-model="formData.brand">
                        <option value="">{{ lang.please_select }}</option>
                        <option :value="brand" v-for="brand in brands">
                            {{ brand.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label class="mb-1">{{ lang.Type }} <span class="text-danger">*</span></label>
                    <div class="row custom-options-checkable">
                        <div
                            class="col-md-6"
                            v-for="(type, i) in types_list"
                            :key="i"
                        >
                            <div
                                class="border rounded bg-light p-1"
                                :class="{
                                    'select-active': formData.type == type,
                                }"
                                @click="formData.type = type"
                                role="button"
                            >
                                <p class="mb-0">{{ type.value }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-12">
                <div class="row">

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="mb-1">{{ lang.Taxable }}</label>
                            <div class="form-check form-switch">
                                <input type="checkbox" class="form-check-input" id="customSwitch1" v-model="formData.taxable" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6" v-if="formData.taxable">
                        <div class="form-group">
                            <label class="mb-1">{{ lang.Tax }} <span class="text-danger">*</span></label>
                            <select class="form-control form-control-lg" v-model="formData.tax">
                                <option value="">{{ lang.please_select }}</option>
                                <option :value="tax" v-for="tax in taxes">
                                    {{ tax.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2" v-if="formData.type.key == 'service'">
            <div class="col-md-3 col-12">
                <div class="form-group">
                    <label>{{ lang.Name }}Serices Price</label>
                    <input
                        type="number"
                        step="0.1"
                        class="form-control form-control-lg"
                        v-model="formData.service_price"
                    />
                </div>
            </div>
        </div>

        <div v-if="formData.type.key == 'product'">
            <h4>{{ lang.main_unit }} <small class="text-danger">{{ lang.MinimumSealableUnit }}</small></h4>
            <div class="bg-light p-1 rounded border">
                <div class="row mb-1">
                    <div class="form-group overflo-hidden">
                        <div class="form-check form-switch float-start">
                            <input type="checkbox" class="form-check-input" id="customSwitch1" v-model="formData.is_weight" />
                        </div>
                        <label>{{ lang.SalebyWeight }}</label>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-md-2 col-12">
                        <div class="form-group">
                            <label>{{ lang.Unit }}</label>
                            <select class="form-control form-control-lg" v-model="formData.main_unit.name">
                                    <option value="">{{ lang.please_select }}</option>
                                    <optgroup label="Each Units">
                                        <option :value="u" v-for="u in each_units">
                                            {{ u.value }} {{ u.ar }}
                                        </option>
                                    </optgroup>
                                    <optgroup label="Scaleable Units">
                                        <option :value="u" v-for="u in weight_units">
                                            {{ u.value }} {{ u.ar }}
                                        </option>
                                    </optgroup>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-2 col-12">
                        <div class="form-group">
                            <label>{{ lang.CostPrice }}</label>
                            <input
                                type="number"
                                class="form-control form-control-lg"
                                placeholder="10"
                                v-model="formData.main_unit.cost_price"
                            />
                            <small class="text-danger">{{ lang.WithoutTax }}</small>
                        </div>
                    </div>

                    <div class="col-md-2 col-12">
                        <div class="form-group">
                            <label>{{ lang.SalePrice }}</label>
                            <input
                                type="number"
                                class="form-control form-control-lg"
                                placeholder="15"
                                v-model="formData.main_unit.sale_price"
                            />
                            <small class="text-danger">{{ lang.WithoutTax }}</small>
                        </div>
                    </div>


                    <div class="col-md-6 col-12">
                        <div class="form-group">
                            <label>{{ lang.Barcodes }}</label>
                            <vue3-tags-input
                            :tags="formData.main_unit.barcodes"
                            placeholder="Barcodes Here"
                            class="form-control form-control-tags"
                            @on-tags-changed="handleChangeTag"
                            />
                            <button type="button" class="btn btn-link p-0" @click="generateBarcode">{{ lang.GenerateBarcode }}</button>
                        </div>
                    </div>
                </div>
                <hr>
                <h4 class="mb-1">{{ lang.AvailableQuantities }}</h4>
                <div class="row mb-1" v-for="branch in formData.main_unit.branches" :key="branch.id">
                    <div class="col-md-3 col-12">{{ branch.name }}</div>
                    <div class="col-md-9 col-12">
                        <input type="number" step="0.1" class="form-control form-control-lg" v-model="branch.quantity">
                    </div>
                </div>
            </div>
        </div>


        <hr />
        <button class="btn btn-primary waves-effect" type="button" disabled="" v-if="loading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="ms-25 align-middle">{{ lang.Saving }}</span>
        </button>
        <button type="button" class="btn btn-primary" @click="validateData" v-else>{{ lang.Save }}</button>
    </div>
</template>

<script>
import Vue3TagsInput from 'vue3-tags-input';
import axios from 'axios';

export default {
    name: "ProductForm",
    props: {
        action: { required: true },
        lang: { required: true },
        company_id: { required: true },
        categories: { required: true },
        brands: { required: true },
        taxes: { required: true },
        products_count: { required: true },
        product: { required: false },
        branches: { required: true },
    },
    components: {
        Vue3TagsInput
    },
    created () {
        if(this.action == 'create') {
            this.branches.forEach((branch) => {
                this.formData.main_unit.branches.push(Object.assign({}, {branch_id: branch.id, name: branch.en_name + ' - ' + branch.name,  quantity: 0}));
            });
        }
        if(this.action == 'update') {
            this.formData.name = this.product.name;
            this.formData.en_name = this.product.en_name;
            this.formData.category = this.categories.find(cat => {return cat.id == this.product.category_id});
            this.formData.brand = this.brands.find(brand => {return brand.id == this.product.brand_id});
            this.formData.type = this.types_list.find(type => {return type.key == this.product.type});
            this.formData.taxable = this.product.taxable == 1 ? true : false;
            this.formData.tax = this.taxes.find(t => {return t.id == this.product.tax_id});

            let unit = this.product.units.find(u => {return u.type === 'main'});
            this.formData.main_unit.id = unit.id;
            this.formData.main_unit.name = unit.name;
            this.formData.main_unit.cost_price = parseFloat(unit.cost_price);
            this.formData.main_unit.sale_price = parseFloat(unit.price);
            unit.barcodes.forEach(element => {
                this.formData.main_unit.barcodes.push(element.barcode);
            });
        }

    },
    data() {
        return {
            formData: {
                term: "",
                name: "",
                en_name: "",
                category: "",
                brand: "",
                type: "",
                barcode: "",
                taxable: false,
                tax: "",
                is_weight: false,
                service_price: 0,
                main_unit: {
                    id: '',
                    name: '',
                    cost_price: '',
                    sale_price: '',
                    barcodes: [],
                    branches: [],
                },
            },
            errors: [],
            loading: false,

            each_units: [
                {'key':  'pcs', 'value' : 'Piece', 'ar': 'قطعة'},
                {'key':  'pcs', 'value' : 'Piece', 'ar': 'حبة'},
                {'key':  'dosan', 'value' : 'Dosan', 'ar': 'درزن'},
                {'key':  'box', 'value' : 'Box', 'ar': 'شد'},
                {'key':  'box', 'value' : 'Box', 'ar': 'كرتون'},
            ],
            weight_units: [
                {'key': 'kg', 'value' : 'Kilograms', 'ar': 'كيلوغرام'},
                {'key':  'g', 'value' : 'Grams', 'ar': 'غرام'},
                {'key': 'm', 'value' : 'Meter', 'ar': 'متر'},
                {'key': 'cm', 'value' : 'Centimeter', 'ar': 'سنتيمتر'},
                {'key': 'l', 'value' : 'Litre', 'ar': 'لتر'},
                {'key': 'ml', 'value' : 'Milliliter', 'ar': 'مليلتر'},
            ],
            tag: "",
            tags: [],
            types_list: [
                { key: "product", value: "Product" },
                { key: "service", value: "Service" },
            ],


        };
    },
    methods: {
        generateBarcode(){
            let barcode = '10';
            var seconds = (new Date().getTime() / 1000).toFixed().toString();
            barcode = barcode + this.company_id + seconds;
            if(this.formData.is_weight) {
                barcode = '20';
                let nextProductCode = this.products_count;
                while (nextProductCode.length < 5) {
                    nextProductCode = '0' + nextProductCode;
                }
                barcode = '20' + nextProductCode;
            }
            this.formData.main_unit.barcodes.push(barcode);
        },
        handleChangeTag(tags) {
            this.formData.main_unit.barcodes = tags;
        },
        isNumeric(str) {
            return !isNaN(Number(str));
        },
        validateData(){
            this.errors = [];
            if(this.formData.name == '') {
                this.errors.push('Please Fill Name Input'); return;
            }

            if(this.formData.category == '') {
                this.errors.push('Please Select Category'); return;
            }

            if(this.formData.type == '') {
                this.errors.push('Please Select Type'); return;
            }
            if(this.formData.type.key == 'product') {

                if(this.formData.main_unit.name == '') {
                    this.errors.push('Please Select Unit'); return;
                }

                if(this.formData.main_unit.cost_price == '') {
                    console.log('here');
                    this.errors.push('Please Fill Cost Price Input'); return;
                }
                // if(!this.isNumeric(this.formData.main_unit.cost_price)) {
                //     this.errors.push('Please Fill Cost Price Input in Numeric'); return;
                // }

                if(this.formData.main_unit.sale_price == '') {
                    this.errors.push('Please Fill Sale Price Input'); return;
                }

                // if(!this.isNumeric(this.formData.main_unit.sale_price)) {
                //     this.errors.push('Please Fill Sale Price Input in Numeric'); return;
                // }

                if(this.formData.main_unit.barcodes.length < 1) {
                    this.errors.push('Please Fill Barcode Input or Genrate New Barcodes'); return;
                }
                let quantity_errors = 0;
                this.formData.main_unit.branches.forEach((branch) => {
                    if(!this.isNumeric(branch.quantity)){
                        quantity_errors += 1;
                    }
                });
                if(quantity_errors > 0) {
                    this.errors.push('Please Fill Branch Quantity in Numeric Value'); return;
                }

            }
            if(this.formData.type.key == 'service') {
                if(this.formData.service_price == '') {
                    this.errors.push('Please Fill Service Price Input'); return;
                }
                if(!this.isNumeric(this.formData.service_price)) {
                    this.errors.push('Please Fill Service Price Input in Numeric'); return;
                }
            }
            if(this.formData.taxable) {
                if(this.formData.tax == '') {
                    this.errors.push('Please Select Tax'); return;
                }
            }
            this.storeData();
        },

        storeData(){
            this.loading = true;
            let url = "/products";

            let formData = new FormData();
            formData.append("name", this.formData.name);
            formData.append("en_name", this.formData.en_name);
            formData.append("category_id", this.formData.category.id);
            if(this.formData.brand != '') {
                formData.append("brand_id", this.formData.brand.id);
            }
            formData.append("type", this.formData.type.key);
            formData.append("taxable", this.formData.taxable ? 1 : 0);
            if(this.formData.taxable) {
                formData.append("tax_id", this.formData.tax.id);
            }
            formData.append("is_weight", this.formData.is_weight ? 1 : 0);
            formData.append("uom", this.formData.is_weight ? 'weight' : 'each');
            formData.append("price", this.formData.service_price);
            formData.append("main_unit", JSON.stringify(this.formData.main_unit));
            if(this.action == 'update') {
                formData.append("_method", 'put');
                url = "/products/" + this.product.id;
            }

            axios
                .post(url, formData, { headers: this.headers })
                .then((response) => {
                    console.log(response.data);
                    setTimeout(() => {
                        window.location.href = response.data;
                    }, 1000);
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                    // for (const [key, value] of Object.entries(
                    //     e.response.data.errors
                    // )) {
                    //     this.errors.push(`${value}`);
                    // }
                });
        },

    }
};
</script>

<style scoped>
.select-active {
    background-color: rgba(115, 103, 240, 0.12) !important;
    color: #7367f0 !important;
    border-color: #7367f0 !important;
}
.v3ti {
    border:  1px solid #d8d6de !important;
}
.v3ti--focus{
    border:  1px solid #7367f0 !important;
}
.v3ti .v3ti-new-tag {
    height: auto !important;
}
</style>
