<template>
    <div>
        <section id="dashboard-ecommerce">
    <div class="row">

        <div class="col-lg-4 col-md-4 col-12">
            <div class="card earnings-card">
                <div class="card-body">
                    <h4 class="card-title mb-1">
                        <div class="avatar bg-light-primary me-1">
                            <div class="avatar-content">
                                <i class="fa-solid fa-money-bill fa-2x"></i>
                            </div>
                        </div>
                        {{ lang.Sales }}
                    </h4>
                    <div class="row">
                        <div class="col-4">
                            <div class="font-small-2">{{ lang.Today }}</div>
                            <h5 class="mb-1">{{  statistics.today_sales }} {{ currency }}</h5>
                        </div>
                        <div class="col-4">
                            <div class="font-small-2">{{ lang.ThisWeek }}</div>
                            <h5 class="mb-1">{{  statistics.this_week_sales }} {{ currency }}</h5>
                        </div>
                        <div class="col-4">
                            <div class="font-small-2">{{ lang.ThisMonth }} </div>
                            <h5 class="mb-1">{{  statistics.this_month_sales }} {{ currency }}</h5>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-4 col-12">
            <div class="card earnings-card">
                <div class="card-body">
                    <h4 class="card-title mb-1">
                        <div class="avatar bg-light-danger me-1">
                            <div class="avatar-content">
                                <i class="fa fa-file fa-2x"></i>
                            </div>
                        </div>
                        {{ lang.Purchases }}

                    </h4>
                    <div class="row">
                        <div class="col-4">
                            <div class="font-small-2">{{ lang.Today }}</div>
                            <h5 class="mb-1">{{  statistics.today_purchases }} {{ currency }}</h5>
                        </div>
                        <div class="col-4">
                            <div class="font-small-2">{{ lang.ThisWeek }}</div>
                            <h5 class="mb-1">{{  statistics.this_week_purchases }} {{ currency }}</h5>
                        </div>
                        <div class="col-4">
                            <div class="font-small-2">{{ lang.ThisMonth }}</div>
                            <h5 class="mb-1">{{  statistics.this_month_purchases }} {{ currency }}</h5>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-4 col-12">
            <div class="card earnings-card">
                <div class="card-body">
                    <h4 class="card-title mb-1">
                        <div class="avatar bg-light-success me-1">
                            <div class="avatar-content">
                                <i class="fa-solid fa-dollar-sign fa-2x"></i>
                            </div>
                        </div>
                        {{ lang.Revenue }}

                    </h4>
                    <div class="row">
                        <div class="col-4">
                            <div class="font-small-2">{{ lang.ThisMonth }}</div>
                            <h5 class="mb-1">{{  statistics.this_month_revenue }} {{ currency }}</h5>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>



</section>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "StatisticsComponent",
    props: {
        currency: {required: true},
        lang: {required: true},
    },
    created () {
        this.getData();
    },
    data() {
        return {
            statistics: '',
            loading: false,
        };
    },
    methods: {
        getData(){
            this.loading = true;
            let url = "/panel";

            let formData = new FormData();

            axios
                .post(url, formData, { headers: this.headers })
                .then((response) => {
                    console.log(response.data);
                    this.statistics = response.data;

                })
                .catch((e) => {
                    this.loading = false;
                });
        },

    }
};
</script>

<style scoped>
</style>
