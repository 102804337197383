<template>
    <div>
        <div class="card box-card">
                <div class="card-body">
                    <div class="input-group mb-2">
                        <input type="text" class="form-control form-control-lg bg-light" placeholder="Barcode" autofocus v-model="term">
                        <span class="input-group-text bg-light" @click="searchProduct" role="button">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>

                    <table class="table table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>{{ lang.product }}</th>
                                <th>{{ lang.price }}</th>
                                <th>{{ lang.quantity }}</th>
                                <th>{{ lang.total }}</th>
                                <th>{{ lang.available }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(product, i) in formData.cart" :key="i" :style="{ 'background' : selectedRow == i ? '#d1faddbb' : '' }" @click="selectedRow = i">
                                <td><input type="text" class="form-control" v-model="product.name"></td>
                                <td>
                                    <div class="input-group input-group-merge form-password-toggle">
                                        <input type="number" class="form-control" v-model="product.price">

                                        <span class="input-group-text cursor-pointer">
                                            {{ currency }}
                                        </span>
                                    </div>
                                </td>
                                <!-- <td>{{ product.price }} {{ currency }}</td> -->
                                <td>
                                    <div class="input-group input-group-merge form-password-toggle">
                                        <input type="number" class="form-control" v-model="product.count">

                                        <span class="input-group-text cursor-pointer">
                                            {{ product.unit_name }}
                                        </span>
                                    </div>
                                </td>
                                <td>{{ product.count * product.price }} {{ currency }}</td>
                                <td>{{ product.quantity }}</td>
                                <td>
                                    <button type="button" class="btn btn-danger btn-sm" @click="deleteFromCart(i)">
                                        <i class="fa fa-xmark"></i>
                                    </button>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

    <div class="card total-summery">
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-md-2" v-if="promotion != null">
                    <button type="reset" class="btn btn-light w-100">
                        <h5>{{ lang.promo_discount }}</h5>
                        <h4 class="mb-0">{{ promotionDiscount }}</h4>
                    </button>
                </div>
                <div class="col-12 col-md-2">
                    <button type="reset" class="btn btn-light w-100">
                        <h5>{{ lang.products }}</h5>
                        <h4 class="mb-0">{{ formData.cart.length }}</h4>
                    </button>
                </div>
                <div class="col-12 col-md-2">
                    <button type="button" class="btn btn-light w-100">
                        <h5>{{ lang.subtotal }}</h5>
                        <h4 class="mb-0">{{ cartSubtotal }} {{ currency }}</h4>
                    </button>
                </div>
                <div class="col-12 col-md-2">
                    <button type="button" class="btn btn-light w-100">
                        <h5>{{ lang.tax }}</h5>
                        <h4 class="mb-0">{{ cartTax }} {{ currency }}</h4>
                    </button>
                </div>
                <div class="col-12 col-md-1">
                </div>
                <div class="col float-end" v-if="formData.cart.length">
                    <button type="button" class="btn btn-primary w-100" @click="openPaymentModal" title="Press = to quick Open Payment Dialog">
                        <h5 class="text-white">{{ lang.pay }}</h5>
                        <h4 class="text-white mb-0">{{ cartTotal }} {{ currency }}</h4>
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade" id="paymentModal">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-transparent">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body pb-5 ">
                <div class="container">
                    <div class="form-group mb-2">
                        <label>{{ lang.client }}</label>
                        <div class="row mb-1">
                            <div class="col-md-11">
                                <v-select
                                    class="style-chooser"
                                    label="name"
                                    :options="customers"
                                    v-model="formData.customer">
                                </v-select>
                            </div>
                            <div class="col-md-1">
                                <span class="btn btn-primary btn-sm" role="button" @click="enableNewCustomerForm"><i class="fa fa-plus"></i></span>
                            </div>
                        </div>

                        <div v-if="add_new_customer || formData.customer.id != ''">
                            <div class="row mb-1">
                                <div class="col col-md-6">
                                    <input type="text" class="form-control form-control-lg" placeholder="Name" v-model="formData.customer.name" :readonly="formData.customer.id != ''">
                                </div>
                                <div class="col col-md-6">
                                    <input type="text" class="form-control form-control-lg" placeholder="Mobile" v-model="formData.customer.mobile" :readonly="formData.customer.id != ''">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row mb-1">
                            <div class="col" v-for="(pMethod, i) in payment_methods" :key="pMethod.id">
                                <div
                                role="button"
                                class="payment-method bg-light rounded p-1 text-center"
                                :style="{ 'background' : formData.payment_method.id == pMethod.id ? '#d1faddbb !important' : '', 'border' : formData.payment_method.id == pMethod.id ? '2px solid #b5efc6 !important' : '2px solid #eee !important' }"
                                @click="formData.payment_method = pMethod">
                                <i :class="pMethod.icon" class="fa-2x"></i>
                                    <h4 class="mb-0 mt-1">
                                        {{ pMethod.name }}
                                    </h4>
                                </div>
                            </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>{{ lang.total }}</label>
                                <input type="text" class="form-control form-control-lg" readonly v-model="formData.payment.total">
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>{{ lang.discount }}</label>
                                <input type="number" step="0.1" class="form-control form-control-lg" v-model="formData.payment.discount">
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>{{ lang.paid }}</label>
                                <input type="number" step="0.1" class="form-control form-control-lg" v-model="formData.payment.paid">
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>{{ lang.remaining }}</label>
                                <input type="text" class="form-control form-control-lg" readonly :value="cartRemaining.remaining">
                                <small v-if="cartRemaining.remaining != 0" class="text-danger">{{ cartRemaining.text }}</small>
                            </div>
                        </div>


                    </div>
                    <button type="button" class="btn btn-primary my-2 w-100" @click="storeCart">
                        <h3 class="text-white mb-0">{{ lang.compelete }} ({{ cartTotal - formData.payment.discount }} {{ currency }})</h3>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

    </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import boxes from './boxMixins';
export default {
    name: "BasicBoxComponent",
    props: {
        lang: {required: true},
        payment_methods: {required: true},
        customers: {required: true},
        currency: {required: true},
        categories: {required: true},
        brands: {required: true},
        promotion: {required: false},
    },
    mixins: [boxes],
    components: {vSelect},
    data() {
        return {
            formData: {
                cart: [],
                payment_method: this.payment_methods[0],
                customer: {
                    id: '',
                    name: '',
                    email: '',
                    mobile: '',
                    tax_number: '',
                },
                payment: {
                    paid: '',
                    discount: 0,
                    total: '',
                }

            },
            add_new_customer: false,
            selectedRow: '',
            term: '',
            products: [],
            errors: [],
            loading: false,
            successAudio: '',
            failedAudio: '',
        };
    },
};
</script>

<style scoped>
table {
    color: black !important;
    font-size: 13pt;
}
.box-card {
    height: 70vh;
    overflow-y: scroll;
}
.item-container {
    background-color: #f5f5f5;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
}
.total-summery h4 {
    margin-bottom: 0px !important;
}
.payment-method {
    text-decoration: none;
}
.payment-method h4{
    text-decoration: none;
}
.payment-method-active {
    background-color: green;
}
.vs__search, .vs__search:focus {
    padding: 1rem !important;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
  padding: 1rem !important;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}

</style>
